import React from 'react'
import { CustomQuoteProvider } from './src/views/custom-quote/context/CustomQuoteManager'
import { UserProvider } from './src/context/UserManager'

export const wrapPageElement = ({ element, props }) =>  {
  const isCustomQuotePage = props.path.startsWith(`/custom-quote/`)
  const needsUser = props.path === `/instant-quote/*` || props.path === `/app/*`
  
  if(isCustomQuotePage) {
    return <CustomQuoteProvider>{element}</CustomQuoteProvider>
  }

  if (needsUser) {
    return <UserProvider>{element}</UserProvider>
  }

  return <>{element}</>
}