import React, { useState, useEffect } from 'react'
import { getFireBase } from '../utils/firebase'

const useFirebase = () => {
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    const lazyApp = import('firebase/app')
    const lazyFirestore = import('firebase/firestore')
    const lazyAuth = import('firebase/auth')
    const lazyStorage = import('firebase/storage')
    
    Promise.all([lazyApp, lazyFirestore, lazyAuth, lazyStorage]).then(values => {
      const firebaseInstance = values[0]

      setInstance(getFireBase(firebaseInstance))
    })
  }, [])

  return instance
}

export default useFirebase