import React, { useState } from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import useFirebase from '../../../hooks/useFirebase'

export const CustomQuoteContext = React.createContext({
  formData: {},
  updateFormData: () => {},
  submitFormData: () => {},
  isFilesUploading: false,
  isDocumentUploading: false,
})

export const CustomQuoteProvider = ({ children }) => {
  const [formData, setFormData] = useState({})
  const [isFilesUploading, setIsFilesUploading] = useState(false)
  const [isDocumentUploading, setIsDocumentUploading] = useState(false)

  const firebase = useFirebase()


  const handleFormData = (formData) => {
    setFormData(formData)
  }

  const handleFileUpload = () => {
    const { files } = formData
    if (!files || !files.length > 0) {
      return
    }

    const storageRef = firebase.storage().ref()

    let uploadPromises = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const fileRef = storageRef.child(`tempFiles/${file.name}`)

      const uploadPromise = new Promise((resolve, reject) => {
        const uploadTask = fileRef.put(file.blob)

        uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(downLoadUrl => {
            resolve(downLoadUrl)
          })
      })
      uploadPromises[i] = uploadPromise
    }

    return Promise.all(uploadPromises)
  }

  const handleCustomQuoteUpload = async (data) => {
    const customQuotesRef = firebase.firestore().collection('customQuotes')

    return customQuotesRef.doc().set(data)
  }

  const handleSubmitForm = async () => {
    try {
      setIsFilesUploading(true)
      const downLoadUrls = await handleFileUpload()
      setIsFilesUploading(false)
      // we replace the files with downloadUrls,
      const newFormData = {
        ...formData,
        files: downLoadUrls || [],
      }

      setIsDocumentUploading(true)
      await handleCustomQuoteUpload(newFormData)
      setIsDocumentUploading(false)

      // For google analytics
      trackCustomEvent({
        category: `CustomProject`,
        action: `Submit`,
        label: `3D Printing`,
      })

    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <CustomQuoteContext.Provider
      value={{
        formData: formData,
        updateFormData: handleFormData,
        submitFormData: handleSubmitForm,
        isFilesUploading: isFilesUploading,
        isDocumentUploading: isDocumentUploading,
      }}
    >
      {children}
    </CustomQuoteContext.Provider>
  )
}