import React, { useState } from 'react'

export const UserContext = React.createContext({
  user: null,
  setCurrentUser: () => {},
  removeCurrentUser: () => {},
})

export const UserProvider = ({ children }) => {
  const localStorage = typeof window !== `undefined` ? window.localStorage : null

  let defaultUser
  if(localStorage) {
    defaultUser = JSON.parse(localStorage.user || null)
  } else {
    defaultUser = null
  }
  const [user, setUser] = useState(defaultUser)

  const handleUser = user => {
    localStorage.setItem('user', JSON.stringify(user))
    setUser(user)
  }

  const handleRemoveUser = () => {
    localStorage.removeItem('user', null)
    setUser(null)
  }

  return (
    <UserContext.Provider
      value={{
        setCurrentUser: handleUser,
        removeCurrentUser: handleRemoveUser,
        user: user,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
